/* Colors */
// Variables
$brandColor: #FFE36C;
$hoveredBrandColor: #FFDB45;
$extremeLightColor: #FCFCFC;
$lightColor: #F9F9FC;
$altLightColor: #EFEFEF;
$contrastColor: #0E101A;

/* Fonts */
// Import Adobe Fonts
@import url("https://use.typekit.net/rnv8sze.css");

// Variables
$mainFont: tenon,
sans-serif;

$regular: 400;
$medium: 500;
$bold: 700;