/* Reset styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: $mainFont;
  color: $contrastColor;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: inherit;
}

ul {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  display: inline-block;
}

small {
  font-size: inherit;
}

img,
video {
  height: auto;
  max-width: 100%;
}