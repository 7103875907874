.app-btn {
  padding: 1.6rem 3.2rem;
  border-radius: 1.2rem;
  font-weight: $medium;
  text-align: center;

  // Reset @blueprint effects
  &:hover {
    color: $contrastColor;
    text-decoration: none;
  }

  &--main {
    background: $brandColor !important;

    &:hover {
      background: $hoveredBrandColor !important;
    }
  }

  &--secondary {
    background: $extremeLightColor;
    border: .1rem solid $altLightColor;

    &:hover {
      background: $altLightColor !important;
    }
  }
}