#root {
  div {
    div:nth-child(1) {
      .bp3-navbar.polotno-side-panel {
        div:nth-child(1) {
          .go1854549255 {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: unset;
            padding: 0;
          }

          .go1854549255.polotno-side-panel-tab {
            background-color: $lightColor;
            padding: 2.5rem;
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            margin-bottom: 2rem;

            div:nth-child(1) {
              svg {
                height: 2rem;
                width: 2rem;
              }
            }

            div:nth-child(2) {
              display: none;
            }
          }

          .go1854549255.polotno-side-panel-tab.active {
            background-color: $brandColor;
          }
        }
      }
    }
  }
}

.polotno-toolbar {
  display: flex;
  flex-direction: column;
  border-right: .1rem solid $altLightColor;
  padding: 2rem;
}

@media screen and (min-width: 501px) {
  .go3464034812 {
    min-width: 12rem !important;
  }
}

.go1798861395 {
  padding: 2rem;
  border-right: .1rem solid $altLightColor;
  min-height: 100vh;
}

.go298234575 {
  padding: 1.6rem !important;
}

.polotno-panel-container {
  .bp3-input-group {
    border-radius: 1.2rem !important;
    margin: 1.2rem 0;
    width: 100%;
  }

  .bp3-input {
    border-radius: 1.2rem;
    padding: 1.6rem .8rem;
    line-height: unset;
    height: unset;
    border: .1rem solid $altLightColor;
    box-shadow: none;
  }

  .bp3-input-group .bp3-input:not(:first-child) {
    padding-left: 4rem;
  }

  .bp3-input-group>.bp3-input-left-container>.bp3-icon,
  .bp3-input-group>.bp3-icon,
  .bp3-input-group .bp3-input-action>.bp3-spinner {
    padding: 1.6rem;
    margin: 0;
  }
}

.bp3-button-group.bp3-vertical.bp3-fixed {
  display: none;
}

.bp3-navbar {
  box-shadow: none;
}

.bp3-icon>svg:not([fill]) {
  fill: $contrastColor;
}

.bp3-button.bp3-minimal:disabled,
.bp3-button.bp3-minimal:disabled:hover,
.bp3-button.bp3-minimal.bp3-disabled,
.bp3-button.bp3-minimal.bp3-disabled:hover {
  color: $contrastColor;
}

.bp3-button .bp3-icon,
.bp3-button .bp3-icon-standard,
.bp3-button .bp3-icon-large {
  color: $contrastColor;
}

@media screen and (min-width: 501px) {
  .go3464034812 {
    overflow-x: unset !important;
    overflow-y: unset !important;
  }
}

.app-footer {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  background-color: white;
  padding: 0 2rem;
  border-top: .1rem solid $altLightColor;
  height: 8rem;

  &__logo img {
    position: relative;
    height: 3rem;
  }

  button:first-of-type {
    margin-right: 2rem;
  }
}